export const GALLERIES = {
  drawings: {
    images: [
      { src: "https://i.imgur.com/lIPw0OM.jpg", height: 95, width: 67 },
      { src: "https://i.imgur.com/r49DGF9.jpg", height: 41, width: 57 },
      { src: "https://i.imgur.com/wb9kEBJ.png", height: 85, width: 93 },
      { src: "https://i.imgur.com/DKnwcN8.png", height: 64, width: 51 },
      { src: "https://i.imgur.com/3B4MxEl.jpg", height: 96, width: 137 },
      { src: "https://i.imgur.com/sD18ZKr.png", height: 17, width: 21 },
      { src: "https://i.imgur.com/POTD1u2.png", height: 37, width: 52 },
      { src: "https://i.imgur.com/QgUa6mJ.jpg", height: 4, width: 3 },
      { src: "https://i.imgur.com/TOp7r2F.png", height: 46, width: 37 },
      { src: "https://i.imgur.com/uzCcIbC.png", height: 71, width: 97 },
      { src: "https://i.imgur.com/4KrHpmZ.jpg", height: 97, width: 69 },
      { src: "https://i.imgur.com/DdLfbgF.jpg", height: 3, width: 4 },
      { src: "https://i.imgur.com/FrMYm80.jpg", height: 95, width: 67 },
      { src: "https://i.imgur.com/BTlJRAb.jpg", height: 65, width: 97 },
      { src: "https://i.imgur.com/o6TpfCD.png", height: 12, width: 17 },
      { src: "https://i.imgur.com/26u8Lnj.png", height: 41, width: 88 },
      { src: "https://i.imgur.com/MZyYjGU.jpg", height: 5, width: 7 },
      { src: "https://i.imgur.com/gWyLkPj.jpg", height: 8, width: 11 },
      { src: "https://i.imgur.com/sm5lg6y.jpg", height: 3, width: 4 },
      { src: "https://i.imgur.com/LSUSRPW.jpg", height: 61, width: 94 },
      { src: "https://i.imgur.com/hxSQBV2.jpg", height: 52, width: 125 },
      { src: "https://i.imgur.com/2bo6iXx.jpg", height: 4, width: 3 },
      {
        src: "https://i.imgur.com/2W2IsPn.jpg",
        height: 79,
        width: 84,
      },
      {
        src: "https://i.imgur.com/cdaAwuL.png",
        height: 79,
        width: 104,
      },
      {
        src: "https://i.imgur.com/6W5Zz9w.jpg",
        height: 67,
        width: 52,
      },
      {
        src: "https://i.imgur.com/jPSzuCA.jpg",
        height: 73,
        width: 54,
      },
      {
        src: "https://i.imgur.com/kIqHqDy.jpg",
        height: 70,
        width: 113,
      },
      {
        src: "https://i.imgur.com/iOhvfnr.gif",
        height: 95,
        width: 169,
      },
      {
        src: "https://i.imgur.com/gnh3Vmf.gif",
        height: 95,
        width: 169,
      },
      {
        src: "https://i.imgur.com/zbN9H8a.png",
        height: 27,
        width: 31,
      },
      {
        src: "https://i.imgur.com/PcANck0.png",
        height: 59,
        width: 73,
      },
      {
        src: "https://i.imgur.com/18jn39O.png",
        height: 29,
        width: 41,
      },
      {
        src: "https://i.imgur.com/EADSNuG.png",
        height: 50,
        width: 37,
      },
      {
        src: "https://i.imgur.com/9h5abMz.jpg",
        height: 4,
        width: 3,
      },
      { src: "https://i.imgur.com/M3ilRoC.png", height: 79, width: 225 },
      { src: "https://i.imgur.com/dvSaUsB.png", height: 91, width: 53 },
      { src: "https://i.imgur.com/OmeCgmo.png", height: 71, width: 69 },
      { src: "https://i.imgur.com/wZ3Jhzn.png", height: 7, width: 5 },
      {
        src: "https://i.imgur.com/QymU1kv.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/WOWdF5a.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/VcgrZfI.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/5YLESqI.jpg",
        height: 58,
        width: 45,
      },
      {
        src: "https://i.imgur.com/NIf6FBr.png",
        height: 19,
        width: 27,
      },
      {
        src: "https://i.imgur.com/9xMeyka.png",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/MLGA2nW.png",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/shp8QoC.png",
        height: 77,
        width: 61,
      },
      {
        src: "https://i.imgur.com/IsITVWo.png",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/qWcSFyJ.jpg",
        height: 70,
        width: 53,
      },
      {
        src: "https://i.imgur.com/cfoCMdf.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/1FElTGc.jpg",
        height: 84,
        width: 115,
      },
      {
        src: "https://i.imgur.com/hIiyo1D.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/ooKeW41.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/DIe6VDJ.jpg",
        height: 33,
        width: 32,
      },
      {
        src: "https://i.imgur.com/JuOMNev.jpg",
        height: 100,
        width: 99,
      },
      {
        src: "https://i.imgur.com/6zml2GL.jpg",
        height: 75,
        width: 122,
      },
      {
        width: 126,
        height: 89,
        src: "https://i.imgur.com/M5P8i6C.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/bzMabf6.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/hZA2K3t.jpg",
      },
      {
        width: 63,
        height: 71,
        src: "https://i.imgur.com/PUvwiKM.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/yvQGcxT.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/bgjdwzx.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/3sHxzow.png",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/MKUcKe0.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/dLFRUnv.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/eup2xSk.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/5tocNvt.jpg",
      },
      {
        width: 49,
        height: 64,
        src: "https://i.imgur.com/9CPheDY.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/gIjwvVg.png",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/1NnJokx.png",
      },
      { src: "https://i.imgur.com/GvW92ZH.png", height: 95, width: 73 },
      { src: "https://i.imgur.com/TH4Keax.png", height: 7, width: 5 },
      { src: "https://i.imgur.com/LuwCA1n.png", height: 7, width: 5 },
      { src: "https://i.imgur.com/5BhWBo8.png", height: 7, width: 5 },
      { src: "https://i.imgur.com/62G6RIR.png", height: 62, width: 45 },
      { src: "https://i.imgur.com/SmO0OJ8.png", height: 27, width: 40 },
      {
        width: 7,
        height: 5,
        src: "https://i.imgur.com/vm7FUhP.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/26ih5iy.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/3quCeuR.jpg",
      },
    ],
    rowHeight: 400,
    preview: "https://i.imgur.com/08lQQn7.png",
    title: "Paintings and drawings done since 2017",
  },
  estrogen: {
    images: [
      {
        src: "https://i.imgur.com/a3nWL1f.png",
        height: 88,
        width: 119,
      },
      {
        src: "https://i.imgur.com/Xqt8cA3.png",
        height: 88,
        width: 139,
      },
      {
        src: "https://i.imgur.com/tcMf783.jpg",
        height: 65,
        width: 97,
      },
      {
        src: "https://i.imgur.com/yQOeWuj.png",
        height: 12,
        width: 17,
      },
      {
        src: "https://i.imgur.com/VpMKHRh.jpg",
        height: 77,
        width: 109,
      },
      {
        src: "https://i.imgur.com/S1fRoeX.png",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/PVhkdZu.png",
        height: 93,
        width: 113,
      },
      {
        src: "https://i.imgur.com/oLobCtf.png",
        height: 93,
        width: 113,
      },
      {
        src: "https://i.imgur.com/HpkXep8.png",
        height: 79,
        width: 225,
      },
    ],
    rowHeight: 400,
    preview: "https://i.imgur.com/taCQhBm.png",
    title: "Estrogen Exhibit",
    description: "Inspired by the Estrogen (Estradiol) molecule C18H24O2",
  },
  wall: {
    images: [
      {
        src: "https://i.imgur.com/4monki6.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/jvpcrmH.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/q62e97t.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/T9wJACV.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/yskBECi.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/2LuqT1h.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/lfEeBx2.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/waMPMXE.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/gHYwhoT.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/SKPHvJx.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/xgGwxwg.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/IfjHqmb.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/ALUybGz.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/WfnkCrz.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/kQV41pv.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/qNAtdGX.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/2HTZ3SA.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/mqxm9c2.jpg",
        height: 3,
        width: 4,
      },
    ],
    rowHeight: 400,
    preview: "https://i.imgur.com/87fIDXr.png",
    title: "Steps of drawing the office wall",
  },
  king: {
    images: [
      {
        src: "https://i.imgur.com/653Bg7H.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/IxnfOiI.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/qYcXlJ3.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/TCLAX94.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/IZ90Euh.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/94fg1uG.jpg?1",
        height: 77,
        width: 71,
      },
      {
        src: "https://i.imgur.com/JdJqYL1.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/p3fZcP5.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/t0z2THz.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/i1aPVXK.jpg",
        height: 3,
        width: 4,
      },
      {
        src: "https://i.imgur.com/EO7jWBA.jpg?2",
        height: 87,
        width: 85,
      },
      {
        src: "https://i.imgur.com/Uu7qMvJ.jpg?2",
        height: 70,
        width: 53,
      },
      {
        src: "https://i.imgur.com/MBBmh6u.jpg",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/BglccBg.jpg?1",
        height: 4,
        width: 3,
      },
      {
        src: "https://i.imgur.com/8IVl5JE.jpg",
        height: 57,
        width: 43,
      },
      {
        src: "https://i.imgur.com/su5MLU2.png",
        height: 50,
        width: 37,
      },
    ],
    rowHeight: 1000,
    preview: "https://i.imgur.com/LXeYPHa.png",
    title: "Steps of drawing The King in Red",
  },
  branding101: {
    images: [
      {
        src: "https://i.imgur.com/pjmh4cB.png",
        height: 100,
        width: 79,
      },
      {
        src: "https://i.imgur.com/cM4lDMt.png",
        height: 97,
        width: 374,
      },
      {
        src: "https://i.imgur.com/Ht7j72D.png",
        height: 77,
        width: 170,
      },
      {
        src: "https://i.imgur.com/Q5sVAm7.png",
        height: 70,
        width: 99,
      },
      {
        src: "https://i.imgur.com/tj3535E.png",
        height: 70,
        width: 99,
      },
      {
        src: "https://i.imgur.com/aQotmln.png",
        height: 18,
        width: 13,
      },
      {
        src: "https://i.imgur.com/8C2B8oT.png",
        height: 89,
        width: 155,
      },
      {
        src: "https://i.imgur.com/pOBxOm8.png",
        height: 55,
        width: 79,
      },
    ],
    rowHeight: 300,
    preview: "https://i.imgur.com/gykvAcX.png",
    description: "Works done in the course Intro to Branding",
    links: ["https://bis.reallyliri.com"],
    title: "Intro to Branding",
  },
  design101: {
    images: [
      {
        src: "https://i.imgur.com/R6u9aYV.jpg",
        height: 93,
        width: 203,
      },
      {
        src: "https://i.imgur.com/V5rcsaW.jpg",
        height: 99,
        width: 70,
      },
      {
        src: "https://i.imgur.com/XwqKF94.png",
        height: 7,
        width: 5,
      },
      {
        src: "https://i.imgur.com/NhgCmde.jpg",
        height: 7,
        width: 5,
      },
      {
        src: "https://i.imgur.com/8DlIFg6.png",
        height: 7,
        width: 5,
      },
      {
        src: "https://i.imgur.com/y4LogOe.png",
        height: 7,
        width: 5,
      },
    ],
    rowHeight: 500,
    preview: "https://i.imgur.com/B5mTN4b.png",
    description: "Works done in the course Intro to Design",
    links: [
      "https://42.reallyliri.com",
      "https://broken-fingaz.reallyliri.com",
    ],
    title: "Intro to Design",
  },
  illustrator: {
    images: [
      {
        width: 41,
        height: 29,
        src: "https://i.imgur.com/LYWlVfN.png",
      },
      {
        width: 95,
        height: 68,
        src: "https://i.imgur.com/9jgDa9q.png",
      },
      {
        width: 29,
        height: 41,
        src: "https://i.imgur.com/MMLIoxq.png",
      },
      {
        width: 91,
        height: 93,
        src: "https://i.imgur.com/w6EirNb.png",
      },
      {
        width: 97,
        height: 52,
        src: "https://i.imgur.com/gpoN6pc.png",
      },
      {
        width: 1,
        height: 1,
        src: "https://i.imgur.com/lKStuQm.png",
      },
      {
        width: 29,
        height: 41,
        src: "https://i.imgur.com/74KTAg0.png",
      },
      {
        src: "https://i.imgur.com/6LtLpAg.png",
        height: 73,
        width: 177,
      },
    ],
    rowHeight: 500,
    preview: "https://i.imgur.com/RxJ46jc.png",
    description: "Works done in the course Intro to Illustrator",
    title: "Intro to Illustrator",
  },
  photoshop: {
    images: [
      {
        src: "https://i.imgur.com/gNOVRJK.jpg",
        height: 41,
        width: 58,
      },
      {
        src: "https://i.imgur.com/bwCnmPA.jpg",
        height: 41,
        width: 58,
      },
      {
        src: "https://i.ibb.co/njK4j2N/hw-3-burn1.png",
        height: 95,
        width: 83,
      },
      {
        src: "https://i.imgur.com/kyIGusE.png",
        height: 49,
        width: 67,
      },
      {
        src: "https://i.ibb.co/xHRH7fN/hw-3-burn3.png",
        height: 17,
        width: 15,
      },
      {
        src: "https://i.ibb.co/WBqKxm0/hw-3-burn8.png",
        height: 2,
        width: 3,
      },
      {
        src: "https://i.ibb.co/txrRMqX/hw-3-burn7.png",
        height: 83,
        width: 146,
      },
      {
        src: "https://i.imgur.com/gm19ChR.png",
        height: 44,
        width: 51,
      },
      {
        src: "https://i.imgur.com/A3FoGxE.jpg",
        height: 33,
        width: 100,
      },
      {
        src: "https://i.imgur.com/Nbq5oml.jpg",
        height: 13,
        width: 30,
      },
      {
        src: "https://i.imgur.com/MkoOqZP.jpg",
        height: 9,
        width: 32,
      },
      {
        src: "https://i.imgur.com/qQwNd0u.jpg",
        height: 25,
        width: 99,
      },
      {
        src: "https://i.imgur.com/dLGIWHW.png",
        height: 43,
        width: 155,
      },
      {
        src: "https://i.imgur.com/h7Moroj.png",
        height: 47,
        width: 167,
      },
    ],
    rowHeight: 300,
    preview: "https://i.imgur.com/5qWgids.png",
    description: "Works done in the course Intro to Photoshop",
    title: "Intro to Photoshop",
  },
  miniatures: {
    images: [
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/cQIuKDt.png",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/bvYbrc7.png",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/h4pI6IH.png",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/FlIQJFv.png",
      },
      {
        width: 83,
        height: 94,
        src: "https://i.imgur.com/L3eZ0YO.png",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/SPZ8X0i.png",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/tPv6x38.png",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/gMmwwks.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/HU6p2DP.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/ffxrdjA.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/WMcrbMB.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/BeOykUI.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/q6MBi4o.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/ZKYPgKG.jpg",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/smlykM6.jpg",
      },
    ],
    rowHeight: 300,
    preview: "https://i.imgur.com/RWnWg82.png",
    description: "D&D miniatures painted with acrylics",
    title: "Miniature Paintings",
  },
  "drawings-old": {
    images: [
      {
        width: 94,
        height: 73,
        src: "https://i.imgur.com/LGWi5mJ.png",
      },
      {
        width: 121,
        height: 83,
        src: "https://i.imgur.com/pMMeZco.jpg",
      },
      {
        width: 17,
        height: 25,
        src: "https://i.imgur.com/CZIuEns.png",
      },
      {
        width: 90,
        height: 71,
        src: "https://i.imgur.com/tB5afCP.jpg",
      },
      {
        width: 71,
        height: 94,
        src: "https://i.imgur.com/7E5DJ0m.png",
      },
      {
        width: 35,
        height: 51,
        src: "https://i.imgur.com/wYDDPom.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/8q0fPAr.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/tsOTVzV.jpg",
      },
      {
        width: 3,
        height: 4,
        src: "https://i.imgur.com/7X4yY5W.jpg",
      },
      {
        width: 64,
        height: 65,
        src: "https://i.imgur.com/kyEVfTq.png",
      },
      {
        width: 34,
        height: 53,
        src: "https://i.imgur.com/PHgs6Qe.png",
      },
      {
        width: 56,
        height: 79,
        src: "https://i.imgur.com/ySXzQwk.png",
      },
      {
        width: 69,
        height: 94,
        src: "https://i.imgur.com/X9FUQU2.png",
      },
      {
        width: 62,
        height: 39,
        src: "https://i.imgur.com/mbKPXcm.png",
      },
      {
        width: 5,
        height: 7,
        src: "https://i.imgur.com/I9pmLcu.png",
      },
      {
        width: 23,
        height: 31,
        src: "https://i.imgur.com/l4XtjHV.png",
      },
      {
        width: 36,
        height: 23,
        src: "https://i.imgur.com/bkle1XT.png",
      },
    ],
    rowHeight: 500,
    preview: "https://i.imgur.com/Nm00cXA.png",
    title: "Drawings done around 2010-2014",
  },
  "designs-old": {
    images: [
      {
        width: 87,
        height: 32,
        src: "https://i.imgur.com/98VnOmu.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/tzfwN2L.png",
      },
      {
        width: 40,
        height: 11,
        src: "https://i.imgur.com/4I4dYTT.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/kBr6qNr.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/RmJjXcF.png",
      },
      {
        width: 42,
        height: 11,
        src: "https://i.imgur.com/bbHL7wg.png",
      },
      {
        width: 1,
        height: 1,
        src: "https://i.imgur.com/YvoLsR7.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/fWzvCW6.png",
      },
      {
        width: 25,
        height: 8,
        src: "https://i.imgur.com/4huW76T.png",
      },
      {
        width: 25,
        height: 8,
        src: "https://i.imgur.com/vHLKEq9.png",
      },
      {
        width: 100,
        height: 59,
        src: "https://i.imgur.com/9mNAgRe.png",
      },
      {
        width: 25,
        height: 8,
        src: "https://i.imgur.com/mV4OKM9.png",
      },
      {
        width: 13,
        height: 5,
        src: "https://i.imgur.com/ABYUx7F.png",
      },
      {
        width: 13,
        height: 5,
        src: "https://i.imgur.com/Q2Fk868.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/qLW1yOP.png",
      },
      {
        width: 45,
        height: 13,
        src: "https://i.imgur.com/uBVaEeT.png",
      },
      {
        width: 37,
        height: 11,
        src: "https://i.imgur.com/w0UzDuo.png",
      },
      {
        width: 18,
        height: 5,
        src: "https://i.imgur.com/U8U0Z7U.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/9YxoLWm.png",
      },
      {
        width: 33,
        height: 25,
        src: "https://i.imgur.com/jjYHvrm.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/eK2GaY3.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/4x5MC4U.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/mKr0k7G.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/cZvbmfc.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/OAOFXLq.png",
      },
      {
        width: 7,
        height: 3,
        src: "https://i.imgur.com/ncE4SZF.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/r7NsUE7.png",
      },
      {
        width: 77,
        height: 18,
        src: "https://i.imgur.com/tAOMJC4.png",
      },
      {
        width: 17,
        height: 5,
        src: "https://i.imgur.com/t9zKd8R.png",
      },
      {
        width: 25,
        height: 6,
        src: "https://i.imgur.com/Y6eASIg.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/iP9p6fk.png",
      },
      {
        width: 25,
        height: 8,
        src: "https://i.imgur.com/cE7aJIS.png",
      },
      {
        width: 205,
        height: 64,
        src: "https://i.imgur.com/xnLmN1S.png",
      },
      {
        width: 66,
        height: 23,
        src: "https://i.imgur.com/DGR3OIj.png",
      },
      {
        width: 25,
        height: 8,
        src: "https://i.imgur.com/jqA4FSc.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/Vbhogbo.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/1QAuy6M.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/0S6U7Ga.png",
      },
      {
        width: 37,
        height: 11,
        src: "https://i.imgur.com/jsphmuG.png",
      },
      {
        width: 80,
        height: 27,
        src: "https://i.imgur.com/qpMCiBd.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/1qaopZK.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/uGgtboi.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/uSSkW2E.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/jZ6FxOq.png",
      },
      {
        width: 32,
        height: 15,
        src: "https://i.imgur.com/2E099Dr.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/e26M9Te.png",
      },
      {
        width: 355,
        height: 98,
        src: "https://i.imgur.com/zJJjkpx.png",
      },
      {
        width: 355,
        height: 98,
        src: "https://i.imgur.com/g89YyFP.png",
      },
      {
        width: 40,
        height: 17,
        src: "https://i.imgur.com/HwHf9G8.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/XIsLfHE.png",
      },
      {
        width: 357,
        height: 100,
        src: "https://i.imgur.com/qf9BppC.png",
      },
      {
        width: 357,
        height: 100,
        src: "https://i.imgur.com/91tFF2w.png",
      },
      {
        width: 7,
        height: 2,
        src: "https://i.imgur.com/Cirgnd7.png",
      },
      {
        width: 3,
        height: 2,
        src: "https://i.imgur.com/WV4mtiW.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/ryPZRkq.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/RzcHyZP.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/rCiejz2.png",
      },
      {
        width: 39,
        height: 10,
        src: "https://i.imgur.com/3RGyLAD.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/htywSur.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/EO3iCjY.png",
      },
      {
        width: 71,
        height: 20,
        src: "https://i.imgur.com/iXVOge5.png",
      },
      {
        width: 58,
        height: 57,
        src: "https://i.imgur.com/74jB9yA.png",
      },
      {
        width: 72,
        height: 25,
        src: "https://i.imgur.com/aID4v0Y.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/vGwAmyP.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/t6HFJKO.png",
      },
      {
        width: 35,
        height: 11,
        src: "https://i.imgur.com/IhodABp.png",
      },
      {
        width: 35,
        height: 11,
        src: "https://i.imgur.com/x7eXpeE.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/iJDvfrJ.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/93bzAcw.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/6FP4Xyo.png",
      },
      {
        width: 263,
        height: 67,
        src: "https://i.imgur.com/8vM6aWZ.png",
      },
      {
        width: 7,
        height: 3,
        src: "https://i.imgur.com/KTuqXgX.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/2MS0hhg.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/MdWV8W0.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/pY0m7B7.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/fTs5tCD.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/Yh80xEX.png",
      },
      {
        width: 62,
        height: 91,
        src: "https://i.imgur.com/8O3WUEC.png",
      },
      {
        width: 19,
        height: 5,
        src: "https://i.imgur.com/HmwioN5.png",
      },
      {
        width: 11,
        height: 4,
        src: "https://i.imgur.com/j52RlDB.png",
      },
      {
        width: 62,
        height: 91,
        src: "https://i.imgur.com/PheYHvS.png",
      },
      {
        width: 62,
        height: 91,
        src: "https://i.imgur.com/VlSaAZR.png",
      },
      {
        width: 317,
        height: 92,
        src: "https://i.imgur.com/KvZ9sPl.png",
      },
      {
        width: 39,
        height: 10,
        src: "https://i.imgur.com/egZhXoU.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/D2qYVrj.png",
      },
      {
        width: 61,
        height: 38,
        src: "https://i.imgur.com/gYrhxoF.png",
      },
      {
        width: 41,
        height: 10,
        src: "https://i.imgur.com/Ej3hLqu.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/8hHjNBP.png",
      },
      {
        width: 25,
        height: 8,
        src: "https://i.imgur.com/zri4DFI.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/rLKxToH.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/6lv4POX.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/7Xr4er5.png",
      },
      {
        width: 35,
        height: 13,
        src: "https://i.imgur.com/mvf0dtT.png",
      },
      {
        width: 40,
        height: 11,
        src: "https://i.imgur.com/rX4QDOG.png",
      },
      {
        width: 35,
        height: 13,
        src: "https://i.imgur.com/DwVQ2dV.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/qrGtSzv.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/n1D11Ss.png",
      },
      {
        width: 79,
        height: 23,
        src: "https://i.imgur.com/AsrErXV.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/oq66pYK.png",
      },
      {
        width: 190,
        height: 51,
        src: "https://i.imgur.com/HspKlTO.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/qXzY5oz.png",
      },
      {
        width: 100,
        height: 37,
        src: "https://i.imgur.com/v1PsAdf.png",
      },
      {
        width: 10,
        height: 3,
        src: "https://i.imgur.com/VKvoC1w.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/Ph71tmx.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/7IZ3kOG.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/4wLLzHA.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/SOL8CAX.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/618k4FZ.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/giRHMhs.png",
      },
      {
        width: 134,
        height: 75,
        src: "https://i.imgur.com/ZAZBvuM.png",
      },
      {
        width: 40,
        height: 9,
        src: "https://i.imgur.com/f3iCBHM.png",
      },
      {
        width: 35,
        height: 13,
        src: "https://i.imgur.com/ppIr89e.png",
      },
      {
        width: 25,
        height: 6,
        src: "https://i.imgur.com/y8YdFN6.png",
      },
      {
        width: 19,
        height: 7,
        src: "https://i.imgur.com/QeD3FDc.png",
      },
      {
        width: 38,
        height: 11,
        src: "https://i.imgur.com/TTA40pB.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/kc4h45d.png",
      },
      {
        width: 35,
        height: 13,
        src: "https://i.imgur.com/F6TP5Nl.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/9eYNmdg.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/xZPIhVT.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/FZAsGdM.png",
      },
      {
        width: 394,
        height: 95,
        src: "https://i.imgur.com/LVUDm8i.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/xbIgOc8.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/BOQJVlO.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/3vRSl3M.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/mvLMW5f.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/pqYvUOi.png",
      },
      {
        width: 364,
        height: 95,
        src: "https://i.imgur.com/pryMyqG.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/O9tYMTA.png",
      },
      {
        width: 1,
        height: 1,
        src: "https://i.imgur.com/vqnHlNy.png",
      },
      {
        width: 5,
        height: 1,
        src: "https://i.imgur.com/3KpAP9H.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/P7NpYuG.png",
      },
      {
        width: 160,
        height: 89,
        src: "https://i.imgur.com/TuM9881.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/9IDU5dt.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/8Uk5nxx.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/DCfDCCX.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/HSjEYcD.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/uPfqf87.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/Jdx15zh.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/09ef4je.png",
      },
      {
        width: 76,
        height: 17,
        src: "https://i.imgur.com/3yeQZLd.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/8vYeTYU.png",
      },
      {
        width: 80,
        height: 99,
        src: "https://i.imgur.com/45fpSf1.png",
      },
      {
        width: 77,
        height: 19,
        src: "https://i.imgur.com/4UWma8H.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/JSCFQwL.png",
      },
      {
        width: 5,
        height: 3,
        src: "https://i.imgur.com/pBAVNLn.png",
      },
      {
        width: 1,
        height: 1,
        src: "https://i.imgur.com/pSXwju6.png",
      },
      {
        width: 40,
        height: 17,
        src: "https://i.imgur.com/hMQ53oZ.png",
      },
      {
        width: 38,
        height: 11,
        src: "https://i.imgur.com/Xo8ZdZe.png",
      },
      {
        width: 73,
        height: 18,
        src: "https://i.imgur.com/QPotRFW.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/GReeFc9.png",
      },
      {
        width: 4,
        height: 3,
        src: "https://i.imgur.com/bQsJP8o.png",
      },
      {
        width: 35,
        height: 13,
        src: "https://i.imgur.com/VpnaTC8.png",
      },
      {
        width: 73,
        height: 30,
        src: "https://i.imgur.com/AuX7KjM.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/t4gxOfU.png",
      },
      {
        width: 77,
        height: 18,
        src: "https://i.imgur.com/WfTCUXw.png",
      },
      {
        width: 19,
        height: 6,
        src: "https://i.imgur.com/vJwuKGd.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/iGx1Zj9.png",
      },
      {
        width: 5,
        height: 2,
        src: "https://i.imgur.com/Km0rO98.png",
      },
      {
        width: 41,
        height: 12,
        src: "https://i.imgur.com/O618WOQ.png",
      },
      {
        width: 71,
        height: 70,
        src: "https://i.imgur.com/7IJWyLw.png",
      },
      {
        width: 10,
        height: 3,
        src: "https://i.imgur.com/7yP9EQS.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/j9L0EBR.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/4z07Xrs.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/o2x85Zw.png",
      },
      {
        width: 4,
        height: 1,
        src: "https://i.imgur.com/DzmCPtw.png",
      },
      {
        width: 40,
        height: 11,
        src: "https://i.imgur.com/1ifHDf4.png",
      },
    ],
    rowHeight: 100,
    preview: "https://i.imgur.com/LMpIjiH.png",
    description: 'Old style designs ("signatures") done around 2008-2010',
    title: "Old Designs",
  },
  corvus: {
    images: [
      {
        src: "https://i.imgur.com/VRAOgxE.mp4",
        height: 720,
        width: 360,
      },
      {
        src: "https://i.imgur.com/vPQI8R4.png",
      },
      {
        src: "https://i.imgur.com/g6NEi8B.jpg",
      },
    ],
    preview: "https://i.imgur.com/3LTcWRD.png",
    links: ["http://corvus-analysis.reallyliri.com/"],
    title: "Corvus Corpus Project",
    maxVw: 60,
  },
  horrorun: {
    images: [
      {
        src: "https://i.imgur.com/7KIMpQM.png",
        height: 41,
        width: 11,
      },
    ],
    preview: "https://i.imgur.com/J4swtzk.png",
    links: ["https://horrorrun.reallyliri.com/"],
    title: "Horrorrun Mobile App",
    maxVw: 60,
  },
  junana: {
    images: [
      {
        src: "https://i.imgur.com/OpBbE0O.png",
        height: 32,
        width: 9,
      },
    ],
    preview: "https://i.imgur.com/k4dMeRR.png",
    links: ["https://junana.reallyliri.com/"],
    title: "JunanaOni Mobile App",
    maxVw: 60,
  },
  complicube: {
    images: [
      {
        src: "https://i.imgur.com/Ls3scsq.png",
        height: 97,
        width: 42,
      },
      {
        src: "https://i.imgur.com/CStFFOr.png",
        height: 32,
        width: 45,
      },
      {
        src: "https://i.imgur.com/0uXZeXZ.png",
        height: 32,
        width: 45,
      },
    ],
    preview: "https://i.imgur.com/kcRiiCj.png",
    title: "Complicube Website",
    maxVw: 60,
  },
  uiux: {
    images: [
      {
        src: "https://i.imgur.com/fGiNnf7.png",
        height: 2,
        width: 1,
      },
      {
        src: "https://i.imgur.com/xmNzuSa.png",
        height: 2,
        width: 1,
      },
      {
        src: "https://i.imgur.com/GvJkIOI.png",
        height: 100,
        width: 53,
      },
    ],
    preview: "https://i.imgur.com/RukJTCC.png",
    title: "Other Web/Mobile Designs",
  },
};
